<template>
  <footer class="footer has-background-dark has-text-light">
    <div class="content has-text-centered">
      <p>
        Designed and built by
        <a class="has-text-light" href="https://github.com/arifintahu"
          ><i>Miftahul Arifin</i>.</a
        >
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
};
</script>

<style>
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
